<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_from_input"
      width="950"
      @click:outside="close()"
      @keydown.esc="close()"
      scrollable
    >
      <v-card
      tile
      >

        <v-card-title
          class="custom-modal-title"
          :style="backgroundColor"
          primary-title
        >
          {{edit_data ? 'Edit' : 'Create'}} Sales Type
            <v-spacer></v-spacer>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
               <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                    >

                    <v-text-field
                    v-model="name"
                    label="Name"
                    required
                    :rules="nameRules"
                    ></v-text-field>
                    <v-textarea
                      v-model="notes"
                      label="Notes"
                    ></v-textarea>

               </v-form>

          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            class="custom-modal-title"
            @click="close"
          >
            Cancel
          </v-btn>

           <v-btn
            color="success"
            tile
            @click="edit_data ? update() : save()"
            :loading="loading"
            :disabled="!valid"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GlobalMixin from '../../../mixin/index'

export default {
  mixins:[GlobalMixin],
  methods: {
    send_data() {
      const data = new URLSearchParams()
      data.append('note', this.notes)
      data.append('name', this.name)
      data.append('entry_by', this.user.fullname)
      data.append('company_id', this.user.company_id)
      return data
    },
    async save () {
      this.loading = true
      const data = this.send_data()
      await this.axios.post('v1/sales-type/create', data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success submit data',
            color_snakbar: 'success'
          })
          await this.go('sales-type','sales_types')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },

    async update() {
      this.loading = true
      const data = this.send_data()
      await this.axios.put('v1/sales-type/update/' + this.edit_data.id, data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success update data',
            color_snakbar: 'success'
          })
          await this.go('sales-type','sales_types')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },

    close () {
      this.reset()
      this.$emit('close')
    },
    reset () {
      this.notes = ''
      this.name = ''
    }
  },

  data: () => ({
    valid: true,
    lazy: false,
    loading: false,
    name: '',
    notes: '',
    nameRules: [
      v => !!v || 'Field is required'
    ],
    foto: null

  }),

  computed: {
  },

  created () {
    if (this.edit_data) {
      this.notes = this.edit_data.note
      this.name = this.edit_data.name
    }
  }
}
</script>
